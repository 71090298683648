import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text, Anchor, ThemeContext } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import Img from 'gatsby-image';

const customTheme = {
  global: {
    focus: {
      border: {
        color: 'white',
      },
    },
  },
};

const Brochures = ({ data }) => (
  <Layout>
    <Head pageTitle={data.supportJson.title} />
    <ThemeContext.Extend value={customTheme}>
      <Box fill="horizontal">
        <Box width="xlarge" alignSelf="center">
          <SectionTitle title="Product Brochures" />
          <Box
            direction="row-responsive"
            justify="center"
            gap="large"
            margin="small"
            alignSelf="center"
            align="center"
            pad="small"
          >
            <Box width="medium">
              <Box width="230px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic2
                      ? data.supportJson.pagePic2.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OtoSim 2™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="Download Brochure"
                  color="neutral-3"
                  href="https://drive.google.com/open?id=13N8NBD_N30sAF8YzsZlW6f5FdAvAqnyG"
                  alignSelf="start"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
            <Box width="medium">
              <Box width="250px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic3
                      ? data.supportJson.pagePic3.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OphthoSim™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="Download Brochure"
                  color="neutral-3"
                  href="https://drive.google.com/open?id=1e7s_QTye1o0qMN8771z03xUkVtTWzxwU"
                  alignSelf="start"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
            <Box width="medium">
              <Box width="150px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic1
                      ? data.supportJson.pagePic1.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OtoSim Mobile™
              </Text>
              <Anchor
                label="Download Brochure"
                color="neutral-3"
                href="https://drive.google.com/open?id=1_qQpBZfW2OqNVSMcWko-XEEyWRUa6oKT"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
            </Box>
            
            <Box width="medium">
              <Box width="150px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic5
                      ? data.supportJson.pagePic5.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OphthoSim Mobile™
              </Text>
              <Anchor
                label="Download Brochure"
                color="neutral-3"
                href="https://drive.google.com/open?id=1vQwfZt0YQ01Wy1gdFLakalhczIjy4IgA"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
            </Box>
            
            <Box width="medium">
              <Box width="160px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic4
                      ? data.supportJson.pagePic4.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                textAlign="center"
              >
                OtoSim Educators Portal™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="Download Brochure"
                  color="neutral-3"
                  href="https://drive.google.com/open?id=1G1ks0Drq1MRQJGUm9npqD91jcFuPyNm4"
                  alignSelf="start"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeContext.Extend>
  </Layout>
);

Brochures.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Brochures;

export const query = graphql`
  query BrochuresQuery {
    supportJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic3 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic4 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
       pagePic5 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
 `;
